<div class="title"></div>
<div class="content">
  <form (keydown.enter)="formInvalid ? $event.preventDefault() : handleSave()">
    @if (ruleFormGroup) {
      <div [formGroup]="ruleFormGroup">
        <div class="header">
          <div class="header-left">
            <span class="font-weight-500 fs-16 lh-24">Levels: </span>
            <span>{{ (bpTemplate.levels_label | levelsStringSplit).join(', ') }}</span>
          </div>
          <div class="header-right" aria-label="toggle active">
            <span>Active</span>
            <mat-slide-toggle (change)="setActive($event)" [checked]="headerInfo.get('is_active').value" />
          </div>
        </div>

        <app-general-form formGroupName="generalInfo" [modalView]="true" />
        @if (bpTemplate && conditions.length) {
          <app-best-practices-template-dialog-inputs [templateRule]="bpTemplate" [conditions]="conditions" [isNew]="true" />
        }
        @if (allCheckboxesUnchecked) {
          <div class="mt-8 error-message">You have to choose at least one condition.</div>
        }
        <div [innerHTML]="adjustInstructions(bpTemplate.instructions)" class="px-24"></div>
        <div class="mx-24 py-12">
          @if (bpTemplate.type === templateRuleTypes.EKG_BRAND_SAFE && templateEkgBrandSafeInfo.sum !== 100) {
            <span class="error-message">
              {{ templateEkgBrandSafeInfo.msg }}
            </span>
          }
        </div>
      </div>
    }
  </form>
</div>
<div class="buttons">
  <button mat-stroked-button color="outline" (click)="cancelCreation($event)">Cancel</button>
  <button mat-raised-button debouncedClick class="ms-auto" [disabled]="formInvalid" ($debouncedClick)="handleSave()">Save</button>
</div>

<button type="button" mat-icon-button class="dialog__close" (click)="_close()">
  <mat-icon>close</mat-icon>
</button>
